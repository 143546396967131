/**
 * Checa se um valor existe.
 * @param value - Valor para checar se existe.
 * Trecho retirado do Angular: {@link https://github.com/angular/angular/blob/dad213e8c71eb31d140b7c9f48fc16827411f22c/packages/common/src/pipes/number_pipe.ts#L289}
 * @returns retorna o valor se existir.
 */
export function isValue(
  value: number | string | null | undefined,
): value is number | string {
  return !(value === null || value === '' || value !== value);
}
