import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const JstHiddenPassword = createIcon({
  defaultProps: {
    fill: 'currentcolor',
  },
  displayName: 'JstHiddenPassword',
  viewBox: '0 0 18 14',
  path: [
    <path
      key="jst-hidden-password-1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.73918 6.99949C4.19651 8.99883 6.40648 10.2661 8.99987 10.2661C11.5933 10.2661 13.8032 8.99883 15.2606 6.99949C13.8032 5.00013 11.5933 3.73281 8.99987 3.73281C6.40648 3.73281 4.19651 5.00013 2.73918 6.99949ZM0.685204 6.4695C2.44598 3.65223 5.41559 1.73281 8.99987 1.73281C12.5842 1.73281 15.5538 3.65223 17.3145 6.46949C17.5172 6.79377 17.5172 7.20522 17.3145 7.52949C15.5538 10.3467 12.5842 12.2661 8.99987 12.2661C5.41559 12.2661 2.44599 10.3467 0.685206 7.52949C0.482536 7.20522 0.482535 6.79377 0.685204 6.4695Z"
      fill="currentcolor"
    />,
    <path
      key="jst-hidden-password-2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5736 0.425706C15.9642 0.81623 15.9642 1.4494 15.5736 1.83992L3.84031 13.5733C3.44979 13.9638 2.81662 13.9638 2.4261 13.5733C2.03557 13.1827 2.03557 12.5496 2.4261 12.159L14.1594 0.425706C14.55 0.0351814 15.1831 0.0351814 15.5736 0.425706Z"
      fill="currentcolor"
    />,
  ],
});

export default JstHiddenPassword;
