import { service } from 'services';
import { DashboardSellerAgenda } from 'types/agenda';
import { BasicCompanyInfo } from 'types/company';
import {
  AccountBalanceModelResponse,
  DashboardFutureReceivableModel,
  DashboardTotalValuesModel,
  DashboardVolumeTansactionModel,
  GenericGraphDataModel,
  LatestCampaignModel,
} from 'types/dashboard';

const CORE_PATH =
  process.env.NODE_ENV === 'test' ? 'core' : process.env.NEXT_PUBLIC_CORE_PATH;
const SELLER_BFF = process.env.NEXT_PUBLIC_SELLER_BFF;

/**
 * Retorna os dados básicos de um estabelecimento.
 */
export async function getBasicCompanyData() {
  try {
    const response = await service.get<BasicCompanyInfo[]>(
      `/${CORE_PATH}/api/establishment/search?value=`,
    );
    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}
/**
 * Calcula os recebíveis do lojista.
 */
export async function calculateReceivable(establishmentIds: number[]) {
  try {
    const response = await service.get<DashboardFutureReceivableModel>(
      `/${CORE_PATH}/api/sale/calculate-receivable`,
      { params: { establishmentIds } },
    );
    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna a agenda da semana do lojista.
 */
export async function dashboardAgenda(establishmentIds: number[]) {
  try {
    const response = await service.get<DashboardSellerAgenda[]>(
      `/${CORE_PATH}/api/sale/agenda`,
      { params: { establishmentIds } },
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna os valore totais dentro de um intervalo de datas.
 * @param start - Data inicial.
 * @param end - Data final.
 * @param estId - ID dos estabelecimentos.
 */
export async function getTotalValues(
  start: string,
  end: string,
  estId?: number[] | undefined,
) {
  try {
    const response = await service.get<DashboardTotalValuesModel[]>(
      `/${CORE_PATH}/api/sale/kpi/total-values`,
      {
        params: {
          start,
          end,
          establishmentIds: estId,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna a última campanha do lojista.
 */
export async function getLatestCampaign() {
  try {
    const response = await service.get<LatestCampaignModel[]>(
      `/${SELLER_BFF}/v2/api/campaigns/banners`,
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna a média de transações do lojista.
 * @param start - Data inicial.
 * @param end - Data final.
 * @param establishmentIds - ID dos estabelecimentos.
 */
export async function getTransactionAverage(
  start: string,
  end: string,
  establishmentIds: number[] | undefined,
) {
  try {
    const response = await service.get<DashboardTotalValuesModel[]>(
      `/${CORE_PATH}/api/sale/kpi/averages`,
      {
        params: {
          start,
          end,
          establishmentIds,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna os dados para composição do gráfico de vendas por tipo.
 * @param start - Data inicial.
 * @param end - Data final.
 * @param establishmentIds - ID dos estabelecimentos.
 */
export async function getSaleGraphByType(
  start: string,
  end: string,
  establishmentIds: number[] | undefined,
) {
  try {
    const response = await service.get<GenericGraphDataModel>(
      `/${CORE_PATH}/api/sale/graph/sale-type`,
      {
        params: {
          start,
          end,
          establishmentIds,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna os dados para composição do gráfico de vendas por bandeira.
 * @param start - Data inicial.
 * @param end - Data final.
 * @param establishmentIds - id dos estabelecimentos.
 */
export async function getSaleGraphByFlags(
  start: string,
  end: string,
  establishmentIds: number[] | undefined,
) {
  try {
    const response = await service.get<GenericGraphDataModel>(
      `/${CORE_PATH}/api/sale/graph/flag`,
      {
        params: {
          start,
          end,
          establishmentIds,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna os dados para composição do gráfico de volume de vendas.
 * @param start - Data inicial.
 * @param end - data final.
 * @param establishmentIds
 */
export async function getSalesGraphByVolumeTransaction(
  start: string,
  end: string,
  establishmentIds: number[] | undefined,
) {
  try {
    const response = await service.get<DashboardVolumeTansactionModel>(
      `/${CORE_PATH}/api/sale/graph/sales`,
      {
        params: {
          start,
          end,
          establishmentIds,
        },
      },
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retorna os valores disponíveis para exibição dos saldos da Conta Justa.
 */
export async function getAccountBalance(establishmentIds: number[]) {
  try {
    const response = await service.get<AccountBalanceModelResponse>(
      `${SELLER_BFF}/v2/api/account-balance`,
      { params: { establishmentIds } },
    );

    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(`Error: ${error}`);
  }
}
