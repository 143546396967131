import React from 'react';
import { useQuery } from 'react-query';
import {
  calculateReceivable,
  dashboardAgenda,
  getAccountBalance,
  getBasicCompanyData,
  getLatestCampaign,
  getSaleGraphByFlags,
  getSaleGraphByType,
  getSalesGraphByVolumeTransaction,
  getTotalValues,
  getTransactionAverage,
} from 'services/dashboard';

/**
 * Custom Hook para retornar os dados básicos do estabelecimento.
 */
export function useCompanyData() {
  return useQuery('dashboard/companyData', getBasicCompanyData, {
    refetchOnWindowFocus: false,
  });
}
/**
 * Custom Hook para retornar o montante que o lojista tem a receber.
 */
export function useCalculateReceivable(establishmentIds: number[]) {
  const ids = React.useMemo(() => establishmentIds, [establishmentIds]);
  return useQuery(
    ['dashboard/calculateReceivable', ids],
    () => calculateReceivable(establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: establishmentIds?.length >= 1,
    },
  );
}

/**
 * Custom Hook para retornar a agenda da semana do lojista.
 */
export function useDashboardAgenda(establishmentIds: number[]) {
  const ids = React.useMemo(() => establishmentIds, [establishmentIds]);
  return useQuery(
    ['dashboard/agenda', ids],
    () => dashboardAgenda(establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: establishmentIds?.length >= 1,
    },
  );
}

/**
 * Custom hook para retornar os valores totais do estabelecimento no dashboard.
 */
export function useDashboardTotalValues(
  start: string,
  end: string,
  estIds?: number[] | undefined,
) {
  const s = React.useMemo(() => start, [start]);
  const e = React.useMemo(() => end, [end]);
  const establishmentIds = React.useMemo(() => estIds, [estIds]);
  return useQuery(
    ['dashboard/totalValues', s, e, establishmentIds],
    () => getTotalValues(s, e, establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: s !== '',
    },
  );
}

/**
 * Custom hook para retornar a ultima campanha ativa.
 */
export function useLatestCampaign() {
  return useQuery('dashboard/latestCampaign', getLatestCampaign, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
}

/**
 * Custom hook para retornar a média de transações.
 */
export function useDashboardTransactionAverage(
  start: string,
  end: string,
  estIds?: number[],
) {
  const s = React.useMemo(() => start, [start]);
  const e = React.useMemo(() => end, [end]);
  const establishmentIds = React.useMemo(() => estIds, [estIds]);
  return useQuery(
    ['dashboard/transactionAverage', s, e, establishmentIds],
    () => getTransactionAverage(s, e, establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: s !== '',
    },
  );
}

/**
 * Custom hook para retornar as vendas por tipo no dashboard.
 */
export function useDashboardSalesByType(
  start: string,
  end: string,
  estIds: number[],
) {
  const s = React.useMemo(() => start, [start]);
  const e = React.useMemo(() => end, [end]);
  const establishmentIds = React.useMemo(() => estIds, [estIds]);
  return useQuery(
    ['dashboard/transactionByType', s, e, establishmentIds],
    () => getSaleGraphByType(s, e, establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: establishmentIds?.length > 0 && s !== '',
    },
  );
}

/**
 * Custom hook para retornar as vendas por bandeira no dashboard.
 */
export function useDashboardSalesByFlag(
  start: string,
  end: string,
  estIds: number[],
) {
  const s = React.useMemo(() => start, [start]);
  const e = React.useMemo(() => end, [end]);
  const establishmentIds = React.useMemo(() => estIds, [estIds]);
  return useQuery(
    ['dashboard/transactionByFlag', s, e, establishmentIds],
    () => getSaleGraphByFlags(s, e, establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: establishmentIds?.length > 0 && s !== '',
    },
  );
}

/**
 * Custom hook para retornar Dados do gráfico de volume de transações.
 */
export function useDashboardVolumeTransaction(
  start: string,
  end: string,
  estIds: number[],
) {
  const s = React.useMemo(() => start, [start]);
  const e = React.useMemo(() => end, [end]);
  const establishmentIds = React.useMemo(() => estIds, [estIds]);
  return useQuery(
    ['dashboard/volumeTransaction', s, e, establishmentIds],
    () => getSalesGraphByVolumeTransaction(s, e, establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: establishmentIds?.length > 0 && s !== '',
    },
  );
}

/**
 * Custom hook para os valores disponiveis na Conta Justa.
 */
export function useGetAccountBalance(establishmentIds: number[]) {
  const ids = React.useMemo(() => establishmentIds, [establishmentIds]);
  return useQuery(
    ['dashboard/accountBalance', ids],
    () => getAccountBalance(establishmentIds),
    {
      refetchOnWindowFocus: false,
      enabled: establishmentIds?.length >= 1,
    },
  );
}
