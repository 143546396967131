import React from 'react';
import AuthLayout from '../layout/Auth';
import { GetServerSideProps } from 'next';
import { NextPage } from 'next';
import LoginForm from 'components/LoginForm';

type PageProps = {
  userAgentIsStatusCake: boolean;
};

/**
 * Página de autenticação do usuário.
 */
const SignIn: NextPage<PageProps> = () => {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
};

export default SignIn;

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  req,
}) => {
  let userAgentIsStatusCake = false;

  /**
   * Verifica se o usuário está acessando a página através do StatusCake.
   */
  if (req.headers['user-agent']) {
    userAgentIsStatusCake = req.headers['user-agent'].includes('StatusCake');
  }

  return {
    props: {
      userAgentIsStatusCake,
    },
  };
};
