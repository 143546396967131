import { initializeApp } from 'firebase/app';

const SETUP = {
  apiKey: process.env.NEXT_PUBLIC_FB_API_KEY || '',
  authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN || '',
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID || '',
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET || '',
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID || '',
  appId: process.env.NEXT_PUBLIC_FB_APP_ID || '',
  measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID || '',
  databaseURL: process.env.NEXT_PUBLIC_FB_DATABASE_URL || '',
};

const firebase = initializeApp(SETUP);

export default firebase;
