import { useCompanyData } from 'hooks/dashboard';
import React from 'react';
import {
  logEvent,
  getAnalytics,
  setUserId as _setUserId,
} from 'firebase/analytics';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import {
  getFirestore,
  collection,
  query,
  doc,
  DocumentReference,
  DocumentData,
} from 'firebase/firestore';
import { ref, Database, getDatabase } from 'firebase/database';
import {
  useDatabaseUpdateMutation,
  useDatabaseValue,
} from '@react-query-firebase/database';
import firebase from 'utils/firebase';
import { isValue } from '../../utils/isValue';
import { __DEV__ } from 'utils/isDevMode';
import {
  useFirestoreDocument,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';
import { useQuery } from 'react-query';
import { isClient } from 'utils/isClient';
import useRefreshToken from 'hooks/auth/useRefreshToken';
/**
 * Hook para registrar eventos no Firebase.
 */
export const useLogEvent = () => {
  const analytics = isClient ? getAnalytics(firebase) : null;
  const logInstance = (
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventParams?: Record<string, any>,
  ) => {
    if (analytics) {
      logEvent(analytics, eventName, eventParams);
    }
  };
  return logInstance;
};

/**
 * Hook para registrar o ID do usuário no Firebase.
 */
export const useSetUserID = () => {
  const setUserId = (userId: string | number) => {
    const analytics = getAnalytics(firebase);
    _setUserId(analytics, userId?.toString());
  };
  return setUserId;
};

/**
 * Custom hook p/ retorna os dados de uma collection do Firebase Real Time Database.
 * @param collection - nome da collection.
 * @param queryKey - Chave do cache.
 */
export const useRealTimeDB = <T = unknown>(
  collection: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: string | any[],
) => {
  const database = getDatabase(firebase);
  const queryCollection = React.useMemo(() => collection, [collection]);
  const key = React.useMemo(() => queryKey, [queryKey]);
  const dbRef = ref(database as Database, queryCollection);
  const dbSnapshot = useDatabaseValue(
    key,
    dbRef,
    {
      subscribe: false,
    },
    {
      refetchOnWindowFocus: false,
      enabled: isValue(collection),
    },
  );
  return { ...dbSnapshot, data: dbSnapshot?.data as T };
};

/**
 * Custom hook para atualizar uma collection no Firebase Real Time Database.
 * @param collection - nome da collection.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useMutationRealTimeDB = <T = unknown>(collection: string) => {
  const database = getDatabase(firebase);
  const dbRef = ref(database as Database, collection);
  return useDatabaseUpdateMutation(dbRef);
};

/**
 * Custom Hook com dados referente a Feature Flag
 */
export const useRemoteConfig = () => {
  const remoteConfig = getRemoteConfig(firebase);

  remoteConfig.defaultConfig = {
    web_app_feature_flags: JSON.stringify({}),
  };

  const activate = React.useCallback(async () => {
    await fetchAndActivate(remoteConfig);
    return JSON.parse(
      getValue(remoteConfig, 'web_app_feature_flags').asString(),
    );
  }, [remoteConfig]);

  activate();

  if (__DEV__) {
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
  }

  return useQuery('firebase/remote-config', activate, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
};

export const useCloudFirestoreQuery = () => {
  const firestore = getFirestore(firebase);

  return query(collection(firestore, 'jst-remote-config-per-id'));
};
export const useCloudFirestoreRef = (
  est: string,
  collection = 'jst-remote-config-per-id',
) => {
  const firestore = getFirestore(firebase);

  return React.useMemo(() => {
    if (est) {
      return doc(firestore, collection, est);
    }
    return;
  }, [est, firestore, collection]);
};

export const useFirestoreData = (collection = 'jst-remote-config-per-id') => {
  const { data } = useCompanyData();
  const establishmentIds = data
    ? data?.map(establishment => establishment.id)
    : [];
  const firestoreRef = useCloudFirestoreRef(
    data && data.length !== 0 ? data[0].id.toString() : '',
    collection,
  );

  const establishmentFirestore = useFirestoreDocument(
    [collection, establishmentIds[0]],
    firestoreRef as DocumentReference<DocumentData>,
    {
      subscribe: true,
    },
  );
  return {
    isLoadingFirestore: establishmentFirestore.isLoading,
    dataFirestore: establishmentFirestore.data?.data(),
    statusFirestore: establishmentFirestore.status,
  };
};

export const useFirestoreMutation = (
  collection = 'jst-remote-config-per-id',
) => {
  const { data } = useCompanyData();
  const firestoreRef = useCloudFirestoreRef(
    data && data.length !== 0 ? data[0].id.toString() : '',
    collection,
  );
  return useFirestoreDocumentMutation(
    firestoreRef as DocumentReference<DocumentData>,
    { merge: true },
  );
};

export const useFirestoreDataByUser = (
  collection = 'jst-remote-config-per-id',
  defaultValue?: Record<string, unknown>,
) => {
  const { data } = useRefreshToken();
  const userId = data?.seller.identifier;
  const firestoreRef = useCloudFirestoreRef(
    data ? (userId as string) : '',
    collection,
  );

  const establishmentFirestore = useFirestoreDocument(
    [collection, userId],
    firestoreRef as DocumentReference<DocumentData>,
    {
      subscribe: true,
    },
  );

  const isLoadingFirestore = establishmentFirestore.isLoading;
  const dataFirestore = establishmentFirestore.data?.data() ?? defaultValue;
  const statusFirestore = establishmentFirestore.status;

  return {
    isLoadingFirestore,
    dataFirestore,
    statusFirestore,
  };
};

export const useFirestoreMutationByUser = (
  collection = 'jst-remote-config-per-id',
) => {
  const { data } = useRefreshToken();
  const userId = data?.seller.identifier;
  const firestoreRef = useCloudFirestoreRef(
    data ? (userId as string) : '',
    collection,
  );
  return useFirestoreDocumentMutation(
    firestoreRef as DocumentReference<DocumentData>,
    { merge: true },
  );
};

export const useFirestoreDataByDoc = (
  collection = 'jst-remote-config-per-id',
  doc: string,
) => {
  const firestoreRef = useCloudFirestoreRef(doc, collection);

  const establishmentFirestore = useFirestoreDocument(
    [collection, doc],
    firestoreRef as DocumentReference<DocumentData>,
    {
      subscribe: true,
    },
  );

  const isLoadingFirestore = establishmentFirestore.isLoading;
  const dataFirestore = establishmentFirestore.data?.data();
  const statusFirestore = establishmentFirestore.status;

  return {
    isLoadingFirestore,
    dataFirestore,
    statusFirestore,
  };
};
