import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const JstVisiblePassword = createIcon({
  defaultProps: {
    fill: 'currentcolor',
  },
  displayName: 'JstVisiblePassword',
  viewBox: '0 0 17 11',
  path: [
    <path
      key="jst-visible-password"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 8.5C6.10677 8.5 4.06401 7.33871 2.70809 5.50001C4.06401 3.66129 6.10677 2.5 8.5 2.5C10.8932 2.5 12.936 3.66129 14.2919 5.50001C12.936 7.33871 10.8932 8.5 8.5 8.5ZM8.5 0.5C5.11572 0.5 2.31278 2.31275 0.652 4.97002C0.449332 5.29429 0.449333 5.70574 0.652003 6.03001C2.31278 8.68725 5.11572 10.5 8.5 10.5C11.8843 10.5 14.6872 8.68725 16.348 6.03001C16.5507 5.70574 16.5507 5.29429 16.348 4.97001C14.6872 2.31275 11.8843 0.5 8.5 0.5ZM8.5 7.5C9.60457 7.5 10.5 6.60457 10.5 5.5C10.5 4.39543 9.60457 3.5 8.5 3.5C7.39543 3.5 6.5 4.39543 6.5 5.5C6.5 6.60457 7.39543 7.5 8.5 7.5Z"
      fill="currentcolor"
    />,
  ],
});

export default JstVisiblePassword;
