import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { getRefreshToken } from 'services';
import { useToast } from '@chakra-ui/react';

// 5 minutes in milliseconds
const REFRESH_TOKEN_EXPIRATION_TIME = 5 * 60 * 1000;

// Hook para realizar o refresh token do usuário.
const useRefreshToken = (isEnable = true) => {
  const router = useRouter();
  const toast = useToast();
  return useQuery('auth/refreshToken', getRefreshToken, {
    refetchOnWindowFocus: false,
    refetchInterval: REFRESH_TOKEN_EXPIRATION_TIME,
    enabled: isEnable,
    onError: () => {
      router.push('/login');
    },
    onSuccess: data => {
      if (!data?.seller?.termsOfContract) {
        toast({
          title: 'Aviso!',
          description: `Novo contrato da Conta Justa disponível. Você precisa aceitar os termos de contrato para continuar.`,
          status: 'warning',
          duration: Number.POSITIVE_INFINITY,
          isClosable: true,
          position: 'top',
        });
      }
    },
  });
};

export default useRefreshToken;
